import axios from "axios";
// import { useState } from "react";

// const BASE_URL = "https://cute-panama-hat-fox.cyclic.app/api/v1/";
// export const INDEX_URL = "https://cute-panama-hat-fox.cyclic.app";

// const BASE_URL = "https://api.admethub.ng/api/v1/";
// export const INDEX_URL = "https://api.admethub.ng";

const BASE_URL = "https://api.admehub.ng/api/v1/";
export const INDEX_URL = "https://api.admehub.ng";

// const BASE_URL = "http://localhost:5000/api/v1/";
// export const INDEX_URL = "http://localhost:5000";

const TOKEN = "";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { authorization: `Bearer ${TOKEN}` },
});
